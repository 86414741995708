import { ref } from 'vue';
import { accountLang, getUserIncome, shareForUser, userData } from './user';
import { getSetting } from './settings';
import { getBalance, profileData } from './profile';
import { getReferralLink } from './referral';

export const lang = ref('ru');
export const shareReward = ref(null);
export const userIncome = ref(null);

export const getReward = async () => {
	shareReward.value = await getSetting('share_reward');
};

export const getIncome = async () => {
	userIncome.value = await getUserIncome();
};

export const share = async () => {
	await shareForUser();

	let result = await getReferralLink(profileData.value.serverData._id);
	let url = result.link;

	let text =
		lang.value === 'ru'
			? `Играя в Honey Harvest, я уже заработал 
            ${Number(userIncome.value.ton).toFixed(3)} TON и 
            ${Number(userIncome.value.honey).toFixed(
							3
						)} Мёда (GH)! Присоединяйся к игре и зарабатывай вместе со мной!`
			: `By playing Honey Harvest, I have already earned 
            ${Number(userIncome.value.ton).toFixed(3)} TON and 
            ${Number(userIncome.value.honey).toFixed(
							3
						)} Honey (GH)! Join the game and earn with me!`;

	window.location.href = `https://t.me/share/url?url=${url}&text=${text}`;

	let balance = await getBalance();
	document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
	document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));

	hideShareModal();
};

export const showShareModal = () => {
	getReward();
	getIncome();

	lang.value = userData.value?.language ?? accountLang.value;

	let modal = document.getElementById('shareModal');

	if (modal !== null) {
		modal.className = 'share-modal';
	}
};

export const hideShareModal = () => {
	let modal = document.getElementById('shareModal');

	if (modal !== null) {
		modal.className = 'share-modal hide';
	}
};
