<script setup>
import { onMounted } from 'vue';
import router from "@/router.js";
import { apiaryText, infoText, loadLoc, profileText, storeText, tapalkaText } from '@/utils/localize';

const changePage = async page => {
    await router.push({
        name: page
    });
};

onMounted(async () => {
    await loadLoc();
});
</script>

<template>
    <footer class="footer">
        <button id="route_market" class="rout noselect" @click="changePage('market')">
            <img class="icon-footer noselect" src="../assets/images/diamond.png" alt="market">
            <span class="white noselect">{{ storeText }}</span>
        </button>
        <button id="route_info" class="rout noselect" @click="changePage('info')">
            <img class="icon-footer noselect" src="../assets/images/info.png" alt="info">
            <span class="white noselect">{{ infoText }}</span>
        </button>
        <button id="route_home" class="rout noselect" @click="changePage('home')">
            <img class="icon-footer noselect" src="../assets/images/home.png" alt="home">
            <span class="white noselect">{{ apiaryText }}</span>
        </button>
        <button id="route_barrel" class="rout noselect" @click="changePage('barrel')">
            <img class="icon-footer noselect" src="../assets/images/barrel.png" alt="barrel">
            <span class="white noselect">{{ tapalkaText }}</span>
        </button>
        <button id="route_profile" class="rout noselect" @click="changePage('profile')">
            <img class="icon-footer noselect" src="../assets/images/profile.png" alt="profile">
            <span class="white noselect">{{ profileText }}</span>
        </button>
    </footer>
</template>

<style>
    .footer {
        width: 90%;
        max-height: 10vh;
        background-image: url('../assets/images/footer_bg.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-around;
        padding: 4vw 0 7vw 0;
        white-space: pre-line;
        color: #fff;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        z-index: 10;
        object-fit: contain;
        
    }

    .rout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .icon-footer {
        width: 8vw;
        height: 8vw;
    }
</style>