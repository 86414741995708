<script setup>
import ProfileScreen from '../components/screens/ProfileScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("background.png");
});
</script>

<template>
    <ProfileScreen/>
</template>