<script setup>
import LoadScreen from '../components/screens/LoadScreen.vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onMounted(() => {
    setTimeout(() => {
        router.push({ name: 'home' });
    }, 4000);
});
</script>

<template>
    <LoadScreen /> 
</template>
