<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import DepositModal from "@/components/modals/DepositModal.vue"
import ShareModal from "./components/modals/ShareModal.vue";
import NotificationsModal from "./components/modals/NotificationsModal.vue";
import EmptyView from "@/views/EmptyView.vue";
import { backgroundUrl } from "./utils/view";
import { expand, tg } from "./utils/telegram";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { isLoading } from "./utils/loader";
import TutorialModal from "./components/modals/TutorialModal.vue";
import DisableDevtool from 'disable-devtool';

const ensureDocumentIsScrollable = () => {
  let isScrollable = document.documentElement.scrollHeight > window.innerHeight;

  if (!isScrollable) {
    document.documentElement.style.setProperty(
      "height",
      "calc(100vh + 1px)",
      "important"
    );
  }
}

const router = useRouter();

router.beforeEach((to, from, next) => {
  if (to.name === 'load') {
    isLoading.value = true;
  }
  next();
});

router.afterEach((to) => {
  if (to.name !== 'load') {
    setTimeout(() => {
      isLoading.value = false;
    }, 0); // Задержка для эмуляции загрузки
  }
});

const isAccessPlatform = ref(
  tg.platform === "android" || tg.platform === "ios" || process.env.VUE_APP_DEVELOPMENT == "true"
);


onBeforeMount(() => {
  if (process.env.VUE_APP_DEVELOPMENT != "true") {
    DisableDevtool();
  }
});

onMounted(async () => {
  expand();
  ensureDocumentIsScrollable();
})
</script>

<template>
  <div class="backgroundview" :style="`background-image: url(backgrounds/${backgroundUrl});`">
    <template v-if="isAccessPlatform">
      <HeaderComponent v-if="!isLoading"/>
      <Suspense>
        <router-view/>
      </Suspense>
      <DepositModal v-if="!isLoading"/>
      <NotificationsModal v-if="!isLoading"/>
      <ShareModal v-if="!isLoading"/>
      <FooterComponent v-if="!isLoading"/>
      <TutorialModal v-if="!isLoading"/>
    </template>
    <template v-else>
      <EmptyView />
    </template>
  </div>
</template>

<style>
@font-face { font-family: PIXY; src: url('assets/fonts/PIXY/PIXY.otf'); }

/* @media (max-width: 480px) and (min-width: 280px) { */
  ::-webkit-scrollbar {
      width: 3vw;
  }

  ::-webkit-scrollbar-track {
    background-color: #805625;
    border-radius: 45px;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 5px 0px 5px 2px #ffffff24;
    background-color: #c08642;
    border-radius: 45px;
  }

  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-image: url('assets/images/woodback.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 100vh;
    font-family: 'PIXY';
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 30px;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .backgroundview {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  a {
    outline: none;
    text-decoration: none;
    background-color: transparent;
    color: inherit;
  }

  a:active {
    background-color: transparent;
    color: inherit;
  }

  button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  button:active {
    transform: translateY(4px);
  }

  p {
    font-family: PIXY;
  }

  .icon {
    width: 6vw;
    height: 6vw;
  }

  .white {
    color: #fff;
    font-size: 3vw;
  }

  .back-link {
    width: 6vw;
    height: auto;
  }

  .menu-profile {
    width: 90%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    overflow: overlay;
  }

  .balance-profile {
    width: 90%;
    height: fit-content;
    background-image: url('/src/assets/images/back_small_scroll_1.png');
    background-repeat: round;
    background-size: cover;
    font-size: 6vw;
    display: flex;
    flex-direction: column;
    color: #B0673A;
  }

  .mush {
    width: 5vw;
    height: 5vw;
    display: flex;
    align-items: flex-start;
  }

  .min-profile {
    font-size: 4vw;
    color: #B0673A;
  }

  .btns {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #B0673A;
    background-color: #FCBD80;
    border: 2px #B0673A solid;
    border-radius: 17px;
  }

  .reflink {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 4vw;
    font-family: PIXY;
    color: #B0673A;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
  }

  .staking {
    width: 100%;
    color: #B0673A;
    font-size: 7vw;
    display: flex;
    justify-content: space-between;
  }

  .link-staking {
    width: 7vw;
    height: auto;
  }

  .getting {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }

  .btns-staking {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #B0673A;
    background-color: #FCBD80;
    border: 2px #000 solid;
    border-radius: 17px;
  }

  .output-staking {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    background-color: #FFD26D;
    border: 0.5vw #EC9637 solid;
    border-radius: 40px;
    color: #B0673A;
    font-size: 4vw;
  }


  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 999;
  }

  .introjs-tooltip {
    background-color: transparent;
    background-image: url('assets/images/back_small_scroll_2.png');
    background-size: cover;
    background-repeat: round;
    box-shadow: none;
    font-family: 'PIXY';
    color: white;
  }

  .introjs-button {
    background-image: url('assets/images/button_bg.png');
    background-size: cover;
    background-repeat: round;
    background-color: transparent;
    border: none;
    text-shadow: none;
    box-shadow: none;
    color: #775236;
    font-family: 'PIXY';
  }

  .introjs-tooltiptext {
    font-family: 'PIXY';
    color: #753f15;
  }

  .introjs-tooltipbuttons {
    border-top: none;
  }

  .introjs-disabled {
    color: #95662f;
    box-shadow:inset 0px 0px 15px 5px #1b1b1b4d;
  }

  .introjs-button:active {
    color: #753f15;
  }

  .introjs-button:focus {
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: yellow;
    font-family: 'PIXY';
  }

  .introjs-skipbutton {
    color: #753f15;
  }

  .introjs-bullets ul li a {
    background: #753f15;
  }

  .introjs-bullets ul li a.active {
    background: yellow;
  }
/*}*/
</style>
