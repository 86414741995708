<script setup>
import TasksScreen from '../components/screens/TasksScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("barrel_bg.png");
});
</script>

<template>
    <TasksScreen/>
</template>