<script setup>
import InfoScreen from '../components/screens/InfoScreen.vue'
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("background.png");
});
</script>

<template>
    <InfoScreen/>
</template>