<script setup>
import { not_notifications, notific } from '@/utils/localize';
import { hideNotificationModal, notifications, language } from '@/utils/notification';
import { onMounted } from 'vue';

const preventCollapse = () => {
    if (window.scrollY === 0) {
        window.scrollTo(0, 1);
    }
};

onMounted(async () => {
    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);
});
</script>

<template>
<div id="notificationModal" class="notification-modal hide">
    <div class="notification-modal-window">
        <div class="notification-popup">
            <div class="notification-popup-header">
                <h1 class="popup_header">{{ notific }}</h1>
                <button @click="hideNotificationModal">
                    <img class="back-link" src="../../assets/images/back_button.png" alt="back" />
                </button>
            </div>
            <div class="notification-popup_content scrollable-element">
                <template v-if="notifications && notifications.length > 0">
                    <div class="notification-element" v-for="notification in notifications" :key="notification">
                        <p>{{ notification.date }}</p>
                        <template v-if="language === 'ru'">
                            <p>{{ notification.message_rus }}</p>
                        </template>
                        <template v-else>
                            <p>{{ notification.message_eng }}</p>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="notification-element">
                        <p>{{   not_notifications   }}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.notification-popup {
    font-size: 4.5vw;
    font-family: PIXY;
    color: #753f16;
    text-align: center;
    overflow: auto;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.notification-popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.notification-modal {
    z-index: 100;
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.notification-popup_content {
    font-size: 4.5vw;
    font-family: PIXY;
    color: #753f16;
    text-align: center;
    overflow: auto;
    width: 80%;
    height: 85%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.hide {
    display: none;
}

.notification-modal-window {
    background-color: transparent;
    background-image: url("../../assets/images/scroll-info.png");
    background-repeat: round;
    background-size: cover;
    position: fixed;
    z-index: 100;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 5vw;
    flex-direction: column;
    flex-wrap: nowrap;
}

.notification_popup_buttons_panel {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
}

.notification_popup_button {
    color: #753f16;
    background-image: url("../../assets/images/button_bg.png");
    background-size: cover;
    background-repeat: round;
    font-size: 4.5vw;
    font-family: PIXY;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: -webkit-fill-available;
    height: 15vw;
    text-align: center;
    box-sizing: border-box;
    font-size: 4vw;

}

.notification-element {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-evenly;
    gap: 4vw;
    text-align: left;
    width: -webkit-fill-available;
}

.yellow {
    color: yellow;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}
</style>