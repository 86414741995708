<script setup>
import RoadMapScreen from '../components/screens/RoadMapScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("background.png");
});
</script>

<template>
    <RoadMapScreen/>
</template>