<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { connectToWallet, disconnectWallet } from '@/utils/profile';
import { onMounted } from 'vue';
import { loadLoc, wallet_connect, wallet_disconnect } from '@/utils/localize';

const open = async () => await connectToWallet();
const diconnect = async () => await disconnectWallet();

onMounted(async () => {
    await loadLoc();
});
</script>

<template>
    <div id="wallet-container" class="btn-center">
        <template v-if="currentIsConnectedStatus === false">
            <button id="ton-connect" class="output-profile" @click="open">
                <p>{{ wallet_connect }}</p>
            </button>
        </template>
        <template v-else>
            <button id="ton-connect" class="output-profile" @click="diconnect">
                <p>{{ wallet_disconnect }}</p>
            </button>
        </template>
    </div>
</template>

<style scoped>
    .btn-center {
        display: flex;
        justify-content: center;
    }

    .output-profile {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFD26D;
        border: 0.5vw #B0673A solid;
        border-radius: 16px;
        color: #B0673A;
        font-size: 4vw;
    }
</style>