<template>
    <main class="empty_background">
        <img class="empty_bee" src="../../../public/bees/default.gif" alt="bee" />
        <span class="mobile_text">Play on your mobile</span>
        <div class="empty_honey">
            <img class="qr_image" src="../../assets/images/hhg_qr.png" alt="">
        </div>
		<span class="mobile_text">@HoneyHarvestGame_Bot</span>
    </main>
</template>


<style>
.empty_background {
    background-image: url('../../assets/images/woodback.jpg');
    position: absolute;
    background-repeat: round;
    background-size: cover;
    width: 100%;
    height: 100vh;
    font-family: 'PIXY';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    z-index: 10000;
}

.mobile_text {
    color: #f7b409;
    font-size: 5vw;
    margin: 20px 0;
    text-align: center;
    max-width: 80%; /* Prevent text from stretching too wide */
}

.empty_honey {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.qr_image {
    width: 60vw; /* Adjust size of the QR image */
    max-width: 300px; /* Set maximum width to prevent it from growing too large */
}

.open_phone {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #f2ff00;
	font-size: 3vw;
	margin-top: 0;
	gap: 10px;
}

.empty_bee {
    width: 30vw;
    height: auto;
    transform: translateY(0) rotate(-20deg);
    animation: loadBee 4s ease-in-out forwards;
    max-width: 200px; /* Limit max width */
}

@keyframes loadBee {
    0% {
        transform: translateY(0) rotate(20deg);
    }
    25% {
        transform: translateY(5vh) rotate(-20deg);
    }
    75% {
        transform: translateY(-5vh) rotate(-20deg);
    }
    100% {
        transform: translateY(0) rotate(20deg);
    }
}
</style>
