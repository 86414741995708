<script setup>
import router from "@/router.js";
import { available, get_it, left, loadLoc, receive, stake, staked, staking } from "@/utils/localize";
import { getBalance, profileData } from "@/utils/profile";
import { getSetting } from "@/utils/settings";
import { checkStaking, getStaking, hoursToHHMM, setStaking } from "@/utils/staking";
import { onMounted, ref } from "vue";

const honey = ref(null);
const inputValue = ref(0);
const percent = ref(0);
const result = ref(0);
const fullResult = ref(0);
const timeLeft = ref(0);

const backToHome = async () => {
    await router.push({
        name: "home"
    })
};

const preventCollapse = () => {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1);
  }
};

const editReuslt = () => {
    if (inputValue.value > honey.value) {
        inputValue.value = honey.value;
    }
    result.value = Number(percent.value.value * inputValue.value).toFixed(2);
};

const setStak = async () => {
    await setStaking(inputValue.value);
    let balance = await getBalance();
    honey.value = balance.honey;
    fullResult.value = Number(profileData?.value?.serverData?.staking?.staked * percent.value?.value).toFixed(3);
    timeLeft.value = hoursToHHMM(await checkStaking());
    document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));
}

onMounted(async () => {
    await getStaking();
    let balance = await getBalance();
    honey.value = balance.honey;
    percent.value = await getSetting("staking_percent");
    await loadLoc();
    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);

    fullResult.value = Number(profileData?.value?.serverData?.staking?.staked * percent?.value?.value).toFixed(3);
    timeLeft.value = hoursToHHMM(await checkStaking());
});
</script>

<template>
    <main class="menu-profile scrollable-element">
        <div class="element-staking">
            <div class="balance-staking">
                <div class="staking">
                    <span>{{ stake }}</span>
                    <button @click="backToHome">
                        <img src="../../assets/images/back_button.png" alt="back" class="link-staking">
                    </button>
                </div>
                <div class="getting">
                    <div class="info-money">
                        <span>{{ available }}</span>
                        <p class="stake">
                            <span>{{ honey ?? 0 }}</span>
                        <img class="mush" src="../../assets/images/honey.png" alt="honey" />
                        </p>    
                    </div>
                    <div class="info-money">
                        <div class="btns-staking">
                            <input type="number" class="reflink" v-model="inputValue" @input="editReuslt">
                        </div>
                        <button class="output-staking" @click="setStak">
                            {{ staking }}
                        </button>
                    </div>
                    <div class="info-money">
                        <span>{{ receive }}</span>
                        <p class="stake">
                            <span>{{ result }}</span>
                            <img class="mush" src="../../assets/images/honey.png" alt="honey" />
                        </p>    
                    </div>
                </div>
            </div>
        </div>
        <div class="element-staking">
            <div class="balance-staking">
                <div class="info-money">
                    <span>{{ staked }}</span>
                    <p class="stake">
                        <span>{{ profileData?.serverData?.staking?.staked ?? "0" }}</span>
                        <img class="mush" src="../../assets/images/honey.png" alt="honey" />
                    </p>    
                </div>
                <div class="info-money">
                    <span>{{ left }}</span>
                    <span>{{ timeLeft ?? "00:00" }}</span>
                </div>
                <div class="info-money">
                    <span>{{ get_it }}</span>
                    <p class="stake">
                        <span>{{ fullResult }}</span>
                        <img class="mush" src="../../assets/images/honey.png" alt="honey" />
                    </p>    
                </div>
            </div>
        </div>
    </main>
</template>

<style>
    .info-money {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 2vw;
    }

    .stake {
        display: flex;
        margin: 0;
        align-items: center;
        gap: 2vw;
    }

  .balance-staking {
      display: flex;
      width: 80%;
      padding: 10% 15% 10% 15%;
      flex-direction: column;
      gap: 5vw;
  }

  .element-staking {
    width: 90%;
    height: -moz-fit-content;
    height: fit-content;
    background-image: url('../../assets/images/back_small_scroll_1.png');
    background-repeat: round;
    background-size: cover;
    font-size: 6vw;
    display: flex;
    flex-direction: column;
    color: #B0673A;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
</style>