<script setup>
import { ref, onMounted } from 'vue';
import {
	connectToWallet,
	profileData,
	getBalance,
	collectWorkBees,
	checkWorkBees,
} from '@/utils/profile';
import WalletModal from '@/components/modals/WalletModal.vue';
import LocalizationComponent from '@/components/LocaliztionComponent.vue';
import { getReferralLink } from '@/utils/referral';
import { withdrawToDeposit } from '@/utils/deposit';
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { getListItems, beesDatasList } from '@/utils/market';
import { tg } from '@/utils/telegram';
import {
	available,
	copy,
	discription_invite,
	income,
	invite_friend,
	invited_people,
	loadLoc,
	total_bees,
	withdraw,
} from '@/utils/localize';

const referralLink = ref('link');

const writeLinkToBuffer = () => {
	navigator.clipboard
		.writeText(referralLink.value)
		.then(() => {})
		.catch((err) => {
			err;
		});
};

const removeBees = () => {
	let beesHeader = document.getElementsByClassName('bee_cell');
	while (beesHeader.length > 0) {
		beesHeader[0].parentNode.removeChild(beesHeader[0]);
	}
};

const updateBeesOnScreen = async (user) => {
	await getListItems();

	removeBees();

	user.bees?.forEach((bee) => {
		let beeType =
			beesDatasList.value.find((data) => data.beeData._id === bee.id)?.beeData.type || 'default';
		let beesUnderHeader = document.getElementById('bees_under_header');

		if (beesUnderHeader !== null && bee.count > 0 && bee.is_fly) {
			beesUnderHeader.innerHTML += `<div class="bee_cell">
                    <img class="bee_icon" src="bees/${beeType}.gif" alt="bee-${beeType}" /> 
                </div>`;
		}
	});
};

const toDeposit = async () => {
	await checkWorkBees();

	if (!currentIsConnectedStatus.value) {
		tg.showAlert('Please, connect to wallet!', async () => await connectToWallet());
		await connectToWallet();
	} else {
		await withdrawToDeposit(profileData.value.serverData._id);
		await collectWorkBees();
		let balance = await getBalance();
		document.getElementById('ton').innerText = Number(balance.ton.toFixed(3));
		document.getElementById('honey').innerText = Number(balance.honey.toFixed(3));

		await updateBeesOnScreen(profileData.value.serverData);
	}
};

const preventCollapse = () => {
	if (window.scrollY === 0) {
		window.scrollTo(0, 1);
	}
};

onMounted(async () => {
	await loadLoc();
	await checkWorkBees();
	let result = await getReferralLink(profileData.value.serverData._id);
	referralLink.value = result.link;

	const scrollableElement = document.querySelector('.scrollable-element');
	scrollableElement.addEventListener('touchstart', preventCollapse);
});
</script>

<template>
	<main
		class="menu-profile scrollable-element"
		v-if="profileData !== null && profileData.serverData !== null"
	>
		<div class="card-profile">
			<div class="card_content">
				<h1>{{ available }}</h1>
				<div class="available">
					<div class="amount">
						<img src="../../assets/images/ton.png" alt="ton" class="icon" />
						<p>{{ Number(profileData.amountCollect.ton.toFixed(3)) }}</p>
					</div>
					<button class="output" @click="toDeposit">
						<p>{{ withdraw }}</p>
					</button>
				</div>
				<div class="available">
					<span>
						<p>{{ income }}</p>
					</span>
					<div class="amount">
						<img src="../../assets/images/ton.png" alt="ton" class="icon" />
						<span>
							<p>{{ Number(profileData.income.ton.toFixed(3)) }}</p>
						</span>
					</div>
				</div>
				<div class="available">
					<span>
						<p>{{ total_bees }}</p>
					</span>
					<div class="amount">
						<img src="../../assets/images/bee.png" alt="ton" class="icon" />
						<span>
							<p>{{ profileData.totalBees }}</p>
						</span>
					</div>
				</div>
				<div class="available">
					<span>
						<p>{{ invited_people }}</p>
					</span>
					<div class="amount">
						<span>
							<p>{{ profileData.countInvited }}</p>
						</span>
					</div>
				</div>

				<WalletModal />
				<LocalizationComponent />
			</div>
		</div>
		<div class="card-profile">
			<div class="balance-prof">
				<div class="invite">
					<span>{{ invite_friend }}</span>
					<span class="min-profile">{{ discription_invite }}</span>
					<div class="btns">
						<input type="text" class="reflink" :value="referralLink" readonly />
					</div>
					<div class="share">
						<button class="copy" @click="writeLinkToBuffer">
							<img class="icon" src="../../assets/images/copy.png" alt="copy" />
							<p>{{ copy }}</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<style>
.invite {
	display: flex;
	justify-content: center;
	flex-direction: column;
	font-size: 6vw;
	color: #b0673a;
	gap: 2vw;
}

.card-profile {
	width: 90%;
	height: fit-content;
	background-image: url('/src/assets/images/back_small_scroll_1.png');
	background-repeat: round;
	background-size: cover;
	font-size: 4vw;
	display: flex;
	flex-direction: column;
	color: #b0673a;
	justify-content: space-around;
	align-items: center;
	flex-wrap: nowrap;
}

.card_content {
	display: flex;
	width: 100%;
	padding: 4vw;
	flex-direction: column;
	text-align: center;
}

.available {
	width: 100%;
	height: 15vw;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: row;
}

.output {
	width: 6em;
	height: min-content;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffd26d;
	border: 0.5vw #b0673a solid;
	border-radius: 16px;
	color: #b0673a;
	font-size: 4vw;
}

.balance-prof {
	display: flex;
	width: 91%;
	padding: 20px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.copy {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5%;
	background-color: #ffd26d;
	border: 2px #b0673a solid;
	border-radius: 16px;
	color: #b0673a;
	font-size: 4vw;
	flex-direction: row;
	flex-wrap: nowrap;
}

.share {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 4vw;
}

.amount {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10%;
	font-size: 1.5em;
}

.profile_icon {
	width: auto;
	height: 10%;
}
</style>
