<script setup>
import router from "@/router.js";
import { loadLoc, roadmap, roadmap_li_1, roadmap_li_10, roadmap_li_11, roadmap_li_2, roadmap_li_3, roadmap_li_4, roadmap_li_5, roadmap_li_6, roadmap_li_7, roadmap_li_8, roadmap_li_9 } from "@/utils/localize";
import { onMounted } from "vue";

const backToInfo = async () => {
    await router.push({
        name: "info"
    })
};

const preventCollapse = () => {
  if (window.scrollY === 0) {
    window.scrollTo(0, 1);
  }
};

onMounted(async () => {
    await loadLoc();
    const scrollableElement = document.querySelector(".scrollable-element");
    scrollableElement.addEventListener("touchstart", preventCollapse);
});
</script>

<template>
    <main class="roadmap-background">
        <div class="roadmap">
            <div class="head-roadmap">
                <h1 class="header-roadmap">{{ roadmap }}</h1>
                <button @click="backToInfo">
                    <img class="back-link" src="../../assets/images/back_button.png" alt="back" />
                </button>
            </div>
            <ol class="text-roadmap scrollable-element">
                <li data-number="1">{{ roadmap_li_1 }}</li>
                <li data-number="2">{{ roadmap_li_2 }}</li>
                <li data-number="3">{{ roadmap_li_3 }}</li>
                <li data-number="4">{{ roadmap_li_4 }}</li>
                <li data-number="5">{{ roadmap_li_5 }}</li>
                <li data-number="6">{{ roadmap_li_6 }}</li>
                <li data-number="7">{{ roadmap_li_7 }}</li>
                <li data-number="8">{{ roadmap_li_8 }}</li>
                <li data-number="9">{{ roadmap_li_9 }}</li>
                <li data-number="10">{{ roadmap_li_10 }}</li>
                <li data-number="11">{{ roadmap_li_11 }}</li>
            </ol>
        </div>
    </main>
</template>

<style>
    .roadmap-background {
        width: 90%;
        display: flex;
        background-image: url(../../assets/images/scroll-info.png);
        background-size: cover;
        justify-content: center;
        flex-direction: column;
        background-repeat: round;
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 2%;
    }

    .roadmap {
        width: 80vw;
        max-height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .head-roadmap {
        width: 80%;
        display: flex;
        justify-content: space-between;
        padding-top: 0vw;
        align-items: flex-start;
    }

    .header-roadmap {
        color: #753f16;
        font-size: 7vw;
        margin: 0px;
    }

    .text-roadmap {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: #753f16;
        font-size: 4vw;
        width: 80%;
        height: 70%;
        overflow: auto;
    }
</style>