import { ref } from 'vue';
import { accountLang, userData } from './user';
import { getRequest } from './restapi';
import { API_ADDRESS_SERVER } from '@/config';

export const language = ref('ru');
export const isHaveNotification = ref(false);
export const notifications = ref([]);

export const getListNotifications = async () => {
	let userNotifications = await getRequest(`${API_ADDRESS_SERVER}/notification/get`, false);
	updateListNotifications(userNotifications);
};

export const showNotificationModal = async () => {
	language.value = userData.value?.language ?? accountLang.value;

	let userNotifications = await getRequest(`${API_ADDRESS_SERVER}/notification/check`, false);
	updateListNotifications(userNotifications);

	let modal = document.getElementById('notificationModal');

	if (modal !== null) {
		modal.className = 'notification-modal';
	}
};

export const hideNotificationModal = () => {
	let modal = document.getElementById('notificationModal');

	if (modal !== null) {
		modal.className = 'notification-modal hide';
	}
};

const updateListNotifications = (data) => {
	if (data !== null && data !== undefined && data.length > 0) {
		notifications.value = JSON.parse(JSON.stringify(data));
		notifications.value.reverse();

		if (notifications.value && notifications.value.length > 0) {
			let not_checked_notification = notifications.value.find(
				(notification) => notification.is_checked === false
			);

			if (not_checked_notification) {
				isHaveNotification.value = true;
			} else {
				isHaveNotification.value = false;
			}
		}
	} else {
		isHaveNotification.value = false;
	}
};
