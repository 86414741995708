import { ref } from 'vue';
import { getRequest, postWithBodyRequest } from './restapi';
import { initData, tgUserAccount } from './telegram';
import { API_ADDRESS_SERVER } from '@/config';

export const account = ref(null);
export const accountId = ref(null);
export const accountName = ref(null);
export const accountLang = ref(null);
export const userData = ref(null);

export const localizes = ref([]);

export const updateData = async () => {
	let userInitData =
		'query_id=AAHs-5ZMAAAAAOz7lkzf5s3-&user=%7B%22id%22%3A1284963308%2C%22first_name%22%3A%22%D0%92%D0%B0%D1%81%D1%8F%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Solevaaaya%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727104317&hash=bd9323837a6237f364b51fbd78754f9e9f8d9e0a3b0fdde5f6089d0dec4e299c';

	if (process.env.VUE_APP_DEVELOPMENT == 'false') {
		account.value = tgUserAccount;
		userInitData = initData;
	}
	userData.value = await authorization(userInitData);

	accountId.value = userData.value._id;
	accountName.value = userData.value.username;
	localStorage.setItem('Authorization', userData.value.key);
	return userData.value;
};

export const changeLanguage = async (id, lang) => {
	const body = { language: lang };
	await postWithBodyRequest(`${API_ADDRESS_SERVER}/user/language`, body);
};

export const loadLocalize = async () => {
	localizes.value = await getRequest(`${API_ADDRESS_SERVER}/localization/get/all`, false);
};

export const getTranslate = async (key) => {
	let result = key;

	localizes.value.forEach((loc) => {
		if (loc.key === key) {
			let lang = userData.value?.language ?? accountLang.value;
			if (lang === 'ru') {
				result = loc.value.ru;
			} else {
				result = loc.value.en;
			}
		}
	});

	return result;
};

export const shareForUser = async () => getRequest(`${API_ADDRESS_SERVER}/user/share`, false);
export const getUserIncome = async () => getRequest(`${API_ADDRESS_SERVER}/user/income`, false);

const authorization = async (initData) =>
	await postWithBodyRequest(`${API_ADDRESS_SERVER}/user/authorization`, { init_data: initData });
