<script setup>
import { ref, onMounted } from "vue";
import { getBalance } from "@/utils/profile";
import { showNotificationModal, isHaveNotification, getListNotifications } from "@/utils/notification";
import { showDepositModal } from "@/utils/deposit";

const ton = ref(0);
const honey = ref(0);

onMounted(async () => {
    let balance = await getBalance();
    ton.value = balance.ton;
    honey.value = balance.honey;

    await getListNotifications();
});
</script>

<template>
    <header class="header" id="header-panel">
        <div class="inside-header">
            <p class="resource" id="resource_ton">
                <img class="icon" src="../assets/images/ton.png" alt="ton"> 
                <span id="ton">
                    {{ Number(ton.toFixed(3)) }}
                </span>
                <button id="depositButton" @click="showDepositModal">
                    <img class="icon" src="../assets/images/plus.png" alt="plus">
                </button>
            </p>
            <p class="resource" id="resource_honey">
                <img class="icon" src="../assets/images/honey.png" alt="honey" /> 
                <span id="honey">
                    {{ Number(honey.toFixed(3)) }}
                </span>
                
                <button id="notificationsButton" @click="showNotificationModal">
                    <template v-if="isHaveNotification">
                        <img class="notification_icon rotate_anim" src="../assets/images/notification_with_point.png" alt="honey" />
                    </template>
                    <template v-else>
                        <img class="notification_icon" src="../assets/images/notification.png" alt="honey" />
                    </template>
                </button>
            </p>
        </div>
        <div id="bees_under_header" class="bees_row">
        </div>
    </header>

    
</template>

<style>
    .header {
        width: 90%;
        height: auto;
        background-image: url('../assets/images/header_bg.png'); /* Убедись, что у тебя есть это изображение */
        background-repeat: no-repeat;
        background-size: 100%;
        color: white;
        /* padding-top: 2vw; */
        transform:  rotate(180deg);
        z-index: 10;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: stretch;
    }

    .resource {
        display: flex;
        align-items: center;
        gap: 10%;
        font-size: 5vw;
    }

    .inside-header {
        transform: rotate(180deg);
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 30%;
        padding: 1%;
        gap: 1%;
    }

    .bees_row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: -webkit-fill-available;
        transform: rotate(180deg);
    }

    .bee_cell {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
    }

    .bee_icon {
        width: 9vw;
        height: 9vw;
    }

    .notification_icon {
        width: 7vw;
        height: 7vw;
    }

    .rotate_anim {
        animation: notification 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    }

    @keyframes notification {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(15deg);
        }
        100% {
            transform: rotate(-15deg);
        }
    }
</style>