import { ref } from 'vue';
import { putRequest } from './restapi';
import { sendTransaction } from './tonConnect';
import { API_ADDRESS_SERVER } from '@/config';
import { load } from './profile';

export const enableWithdraw = ref(false);

export const showDepositModal = async () => {
	await load();
	document.getElementById('depositModal')?.show();
};

export const hideDepositModal = () => document.getElementById('depositModal')?.close();

export const makeDeposit = async (depositComment, amount) => {
	await sendTransaction(amount, depositComment);
};

export const withdrawToDeposit = async (idUser) => {
	idUser;
	await putRequest(`${API_ADDRESS_SERVER}/deposit/income/ton`);
};
