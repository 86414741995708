import { API_ADDRESS_SERVER } from '@/config';
import { getRequest, postRequest } from './restapi';

export const getStaking = async () => await getRequest(`${API_ADDRESS_SERVER}/staking/get`);
export const checkStaking = async () => await getRequest(`${API_ADDRESS_SERVER}/staking/check`);
export const setStaking = async (amount) =>
	await postRequest(`${API_ADDRESS_SERVER}/staking/set/${amount}`);

export const hoursToHHMM = (hours) => {
	let h = Math.trunc(hours);
	let m = Math.abs(Math.round((hours - h) * 60));

	if (m >= 60) {
		m -= 60;
		h += 1;
	}

	return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
};
