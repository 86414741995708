import { API_ADDRESS_SERVER } from '@/config';
import { getRequest } from './restapi';
import { ref } from 'vue';

export const tasksList = ref([]);

export const getListTasks = async () => {
	tasksList.value = [];

	let tasks = await getRequest(`${API_ADDRESS_SERVER}/tasks/get/all`, true);

	tasks.forEach((taskData) => {
		tasksList.value.push({ taskData });
	});
};

export const checkCompliteTask = async (task) => {
	let result = await getRequest(`${API_ADDRESS_SERVER}/tasks/complete/${task._id}`);
	return result;
};
