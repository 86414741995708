<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
// import "intro.js/introjs.css";
import 'intro.js/minified/introjs.min.css';
import introJs from 'intro.js';
import {
	loadLoc,
	tutor_step_cupboard,
	tutor_step_staking,
	tutor_step_notifications,
	tutor_step_market,
	tutor_step_info,
	tutor_step_apiary,
	tutor_step_barrel,
	tutor_step_profile,
	tutor_finish,
	tutor_label,
	tutor_next,
	tutor_prev,
	tutor_step_tasks,
	tutor_step_balance,
} from '@/utils/localize';
import { getRequest } from '@/utils/restapi';
import { API_ADDRESS_SERVER } from '@/config';
import { load, profileData } from '@/utils/profile';

const isCompleted = ref(false);

const tutorSteps = ref([]);

const complete = async () => {
	if (!isCompleted.value) {
		await getRequest(`${API_ADDRESS_SERVER}/user/tutor/complete`, false);
		isCompleted.value = true;
	}
};

onBeforeMount(async () => {
	await load();

	if (profileData?.value?.serverData?.is_complete_tutor) {
		isCompleted.value = profileData.value.serverData.is_complete_tutor;
	} else {
		isCompleted.value = false;
	}
});

onMounted(async () => {
	await loadLoc();

	if (!isCompleted.value) {
		tutorSteps.value = [
			{
				element: document.querySelectorAll('#route_market')[0],
				intro: tutor_step_market.value,
				position: 'top',
			},
			{
				element: document.querySelectorAll('#route_info')[0],
				intro: tutor_step_info.value,
				position: 'top',
			},
			{
				element: document.querySelectorAll('#route_home')[0],
				intro: tutor_step_apiary.value,
				position: 'top',
			},
			{
				element: document.querySelectorAll('#route_barrel')[0],
				intro: tutor_step_barrel.value,
				position: 'top',
			},
			{
				element: document.querySelectorAll('#route_profile')[0],
				intro: tutor_step_profile.value,
				position: 'top',
			},
			{
				element: document.querySelectorAll('#side-button-tasks')[0],
				intro: tutor_step_tasks.value,
				position: 'left',
			},
			{
				element: document.querySelectorAll('#side-button-closet')[0],
				intro: tutor_step_cupboard.value,
				position: 'left',
			},
			{
				element: document.querySelectorAll('#side-button-staking')[0],
				intro: tutor_step_staking.value,
				position: 'left',
			},
			{
				element: document.querySelectorAll('#notificationsButton')[0],
				intro: tutor_step_notifications.value,
				position: 'botton',
			},
			{
				element: document.querySelectorAll('#resource_ton')[0],
				intro: tutor_step_balance.value,
				position: 'botton',
			},
		];

		if (profileData.value && profileData.value.serverData) {
			introJs()
				.setOptions({
					nextLabel: tutor_next.value,
					prevLabel: tutor_prev.value,
					doneLabel: tutor_finish.value,
				})
				.addSteps(tutorSteps.value)
				.onComplete(async () => {
					await complete();
				})
				.onExit(async () => {
					await complete();
				})
				.onSkip(async () => {
					await complete();
				})
				.start();
		}
	}
});
</script>

<template>
	<template v-if="!isCompleted">
		<div class="tutorial-screen">
			<h1>{{ tutor_label }}</h1>
		</div>
	</template>
</template>

<style scoped>
.tutorial-screen {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: -webkit-fill-available;
	width: -webkit-fill-available;
	z-index: 1000;
	color: yellow;
}
</style>
