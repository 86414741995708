import { API_ADDRESS_GAME } from '@/config';
import { load, profileData } from './profile';
import { ref } from 'vue';
import { tg } from '@/utils/telegram';

const socket = ref(null);

export const connect = async () => {
	socket.value = window.io.connect(API_ADDRESS_GAME);

	socket.value.on('connect', async function () {
		await load();
	});

	socket.value.on('disconnect', async function () {
		await load();
	});

	socket.value.on('tap_response', function (msg /*, cb*/) {
		if (msg.status === 200) {
			document.getElementById('honey').innerText = Number(msg.data.toFixed(3));
		} else {
			if (tg.platform === 'unknown') {
				alert('Not enough TON!');
			} else {
				tg.showAlert('Not enough TON!');
			}
		}
	});
};

export const tap = (isDouble, isMulti) => {
	socket.value.emit(
		isMulti ? 'multi_tap' : isDouble ? 'double_tap' : 'tap',
		profileData.value.serverData._id
	);
};
