<script>
export default {
    name: 'VariantWindow',
    props: {
        isModal: String
    }
}
</script>

<script setup>
import router from "@/router.js";
import { onMounted } from "vue";
import { apiary, loadLoc } from "@/utils/localize";



const changePage = async page => {
    await router.push({
        name: page
    });
}

const cancel = async (isdepositModal) => {
    if (isdepositModal === 'true'){
        document.getElementById("depositModal")?.close()
    } else {
        await changePage('home');
    }
};

onMounted(async () => {
    await loadLoc();
});
</script>

<template>
    <div class="side_home_panel">
        <button class="side-button side-button-brown" @click="cancel(isModal)">
            <img class="side-icons" src="../assets/images/home.png" alt="home"/>
            <p>{{ apiary }}</p>
        </button>
    </div>
</template>

<style>
.side_home_panel {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 1vh;
    position: absolute;
    top: 20%;
    z-index: 120;
} 

.side-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2vw;
    font-size: 85%;
    color: #fff;
    background-repeat: round;
    background-size: cover;
    width: fit-content;
    height: min-content;
    padding: 1%;
    padding-left: 4%;
}

.side-button p {
    font-size: 0%;
    visibility: hidden;
}
.side-button:hover p {
    font-size: 85%;
    visibility: visible;
}

.side-button:hover {
    transform: translateX(-1px);
    transform: scale3d(10px);
}

.side-button:active p {
    font-size: 85%;
    visibility: visible;
}

.side-button:active {
    transform: translateX(-1px);
    transform: scale3d(10px);
}

.side-button-brown {
    background-image: url('../assets/images/side_button_brown.png');
}
</style>