import { ref } from "vue"
import { getTranslate, loadLocalize } from "@/utils/user";

export const loadLoc = async () => {
    await loadLocalize();
    await loadTranslations();
    isLoaded.value = true;
};

export const isLoaded= ref(false);

//footer
export const storeText = ref('loading...');
export const infoText = ref('loading...');
export const apiaryText = ref('loading...');
export const profileText = ref('loading...');
export const tapalkaText = ref('loading...');

//home
export const apiary = ref('loading...');

//info
export const discription_info_p1 = ref('loading...');
export const discription_info_p2 = ref('loading...');
export const discription_info_p3 = ref('loading...');
export const discription_info_p4_1 = ref('loading...');
export const discription_info_p4_2 = ref('loading...');
export const discription_info_p4_3 = ref('loading...');
export const discription_info_p5 = ref('loading...');
export const info = ref('loading...');
export const roadmap = ref('loading...');

//roadmap
export const roadmap_li_1 = ref('loading...');
export const roadmap_li_2 = ref('loading...');
export const roadmap_li_3 = ref('loading...');
export const roadmap_li_4 = ref('loading...');
export const roadmap_li_5 = ref('loading...');
export const roadmap_li_6 = ref('loading...');
export const roadmap_li_7 = ref('loading...');
export const roadmap_li_8 = ref('loading...');
export const roadmap_li_9 = ref('loading...');
export const roadmap_li_10 = ref('loading...');
export const roadmap_li_11 = ref('loading...');

//profile
export const available = ref('loading...');
export const withdraw = ref('loading...');
export const income = ref('loading...');
export const total_bees = ref('loading...');
export const invited_people = ref('loading...');
export const wallet_connect = ref('loading...');
export const wallet_disconnect = ref('loading...');
export const invite_friend = ref('loading...');
export const discription_invite = ref('loading...');
export const copy = ref('loading...');

//staking
export const stake = ref('loading...');
export const staked = ref('loading...');
export const staking = ref('loading...');
export const receive = ref('loading...');
export const received = ref('loading...');
export const get_it = ref('loading...');
export const left = ref('loading...');

//market
export const price = ref('loading...');
export const very_soon = ref('loading...');
export const already_bought = ref('loading...');
export const blocked = ref('loading...');
export const honey = ref('loading...');

//tasks
export const tasks = ref('loading...');
export const subscribe = ref('loading...');
export const check = ref('loading...');
export const done = ref('loading...');
export const denied = ref('loading...');
export const сhecking = ref('loading...');
export const reward = ref('loading...');
export const error_status = ref('loading...');

//notifications
export const notific = ref('loading...');
export const not_notifications = ref('loading...');

//tutorials
export const tutor_step_ton = ref('loading...');
export const tutor_step_deposit = ref('loading...');
export const tutor_step_honey = ref('loading...');
export const tutor_step_notifications = ref('loading...');
export const tutor_step_market = ref('loading...');
export const tutor_step_info = ref('loading...');
export const tutor_step_apiary = ref('loading...');
export const tutor_step_barrel = ref('loading...');
export const tutor_step_profile = ref('loading...');
export const tutor_step_tasks = ref('loading...');
export const tutor_step_cupboard = ref('loading...');
export const tutor_step_staking = ref('loading...');
export const tutor_step_balance = ref('loading...');
export const tutor_label = ref('loading...');
export const tutor_next = ref('loading...');
export const tutor_prev = ref('loading...');
export const tutor_finish = ref('loading...');

//deposit
export const deposit_account = ref('loading...');
export const deposit_info = ref('loading...');
export const deposit_deposit = ref('loading...');
export const deposit_withdraw = ref('loading...');

//share
export const share_title = ref('loading...');
export const share_share = ref('loading...');
export const share_close = ref('loading...');

//defense
export const defense_against_laundering = ref('loading...');

//errors
export const error_connect_wallet = ref('loading...');
export const error_min_deposit = ref('loading...');
export const error_min_withdraw = ref('loading...');
export const error_empty_amount_deposit = ref('loading...');
export const error_empty_amount_withdraw = ref('loading...');
export const error_get_wallet_address = ref('loading...');

//friend
export const invite_one_friend = ref('loading...');

const loadTranslations = async () => {
    storeText.value = await getTranslate('store');
    infoText.value = await getTranslate('info');
    tapalkaText.value = await getTranslate('tapalka');
    apiaryText.value = await getTranslate('apiary');
    profileText.value = await getTranslate('profile');

    apiary.value = await getTranslate('apiary');

    discription_info_p1.value = await getTranslate('discription_info_p1');
    discription_info_p2.value = await getTranslate('discription_info_p2');
    discription_info_p3.value = await getTranslate('discription_info_p3');
    discription_info_p4_1.value = await getTranslate('discription_info_p4_1');
    discription_info_p4_2.value = await getTranslate('discription_info_p4_2');
    discription_info_p4_3.value = await getTranslate('discription_info_p4_3');
    discription_info_p5.value = await getTranslate('discription_info_p5');
    info.value = await getTranslate('info');
    roadmap.value = await getTranslate('roadmap');

    roadmap_li_1.value = await getTranslate('roadmap_li_1');
    roadmap_li_2.value = await getTranslate('roadmap_li_2');
    roadmap_li_3.value = await getTranslate('roadmap_li_3');
    roadmap_li_4.value = await getTranslate('roadmap_li_4');
    roadmap_li_5.value = await getTranslate('roadmap_li_5');
    roadmap_li_6.value = await getTranslate('roadmap_li_6');
    roadmap_li_7.value = await getTranslate('roadmap_li_7');
    roadmap_li_8.value = await getTranslate('roadmap_li_8');
    roadmap_li_9.value = await getTranslate('roadmap_li_9');
    roadmap_li_10.value = await getTranslate('roadmap_li_10');
    roadmap_li_11.value = await getTranslate('roadmap_li_11');

    available.value = await getTranslate('available');
    withdraw.value = await getTranslate('withdraw');
    income.value = await getTranslate('income');
    total_bees.value = await getTranslate('total_bees');
    invited_people.value = await getTranslate('invited_people');
    wallet_connect.value = await getTranslate('wallet_connect');
    wallet_disconnect.value = await getTranslate('wallet_disconnect');
    invite_friend.value = await getTranslate('invite_friend');
    discription_invite.value = await getTranslate('discription_invite');
    copy.value = await getTranslate('copy');

    stake.value = await getTranslate('stake');
    staked.value = await getTranslate('staked');
    staking.value = await getTranslate('staking');
    receive.value = await getTranslate('receive');
    received.value = await getTranslate('received');
    get_it.value = await getTranslate('get_it');
    left.value = await getTranslate('left');

    price.value = await getTranslate('price');
    very_soon.value = await getTranslate('very_soon');
    already_bought.value = await getTranslate('already_bought');
    blocked.value = await getTranslate('blocked');
    honey.value = await getTranslate('honey');

    tasks.value = await getTranslate('task');
    subscribe.value = await getTranslate('subscribe');
    check.value = await getTranslate('check');
    done.value = await getTranslate('done');
    denied.value = await getTranslate('denied');
    сhecking.value = await getTranslate('сhecking');
    reward.value = await getTranslate('reward');
    error_status.value = await getTranslate('error');

    notific.value = await getTranslate('notifications');
    not_notifications.value = await getTranslate('not_notifications');

    tutor_step_ton.value = await getTranslate('tutor_step_ton');
    tutor_step_deposit.value = await getTranslate('tutor_step_deposit');
    tutor_step_honey.value = await getTranslate('tutor_step_honey');
    tutor_step_notifications.value = await getTranslate('tutor_step_notifications');
    tutor_step_market.value = await getTranslate('tutor_step_market');
    tutor_step_info.value = await getTranslate('tutor_step_info');
    tutor_step_apiary.value = await getTranslate('tutor_step_apiary');
    tutor_step_barrel.value = await getTranslate('tutor_step_barrel');
    tutor_step_profile.value = await getTranslate('tutor_step_profile');
    tutor_step_tasks.value = await getTranslate('tutor_step_tasks');
    tutor_step_cupboard.value = await getTranslate('tutor_step_cupboard');
    tutor_step_staking.value = await getTranslate('tutor_step_staking');
    tutor_step_balance.value = await getTranslate('tutor_step_balance');
    tutor_label.value = await getTranslate('tutor_label');
    tutor_next.value = await getTranslate('tutor_next');
    tutor_prev.value = await getTranslate('tutor_prev');
    tutor_finish.value = await getTranslate('tutor_finish');

    deposit_account.value = await getTranslate('deposit_account');
    deposit_info.value = await getTranslate('deposit_info');
    deposit_deposit.value = await getTranslate('deposit_deposit');
    deposit_withdraw.value = await getTranslate('deposit_withdraw');

    share_title.value = await getTranslate('share_title');
    share_share.value = await getTranslate('share_share');
    share_close.value = await getTranslate('share_close');

    defense_against_laundering.value = await getTranslate('defense_against_laundering');

    error_connect_wallet.value = await getTranslate('error_connect_wallet');
    error_empty_amount_deposit.value = await getTranslate('error_empty_amount_deposit');
    error_empty_amount_withdraw.value = await getTranslate('error_empty_amount_withdraw');
    error_min_deposit.value = await getTranslate('error_min_deposit');
    error_min_withdraw.value = await getTranslate('error_min_withdraw');
    error_get_wallet_address.value = await getTranslate('error_get_wallet_address');

    invite_one_friend.value = await getTranslate('invite_one_friend');
};

export const getBeeTypeTranslation = async (type) => {
    return await getTranslate(type);
};

export const getBoosterTitleTranslation = async (title) => {
    return await getTranslate(title);
};