<script setup>
import router from '@/router.js';
import { isLoading } from '@/utils/loader';
import { load, profileData } from '@/utils/profile';
import { changeLanguage } from '@/utils/user';

const changePage = async (page) => {
	await router.push({
		name: page,
	});
};

const changeLang = async (language) => {
	await changeLanguage(profileData.value.serverData._id, language);
	await load();
	await changePage('load');
	isLoading.value = true;
	location.reload();
};
</script>

<template>
	<div class="lang_container">
		<button class="lang_button" @click="changeLang('ru')">
			<img class="lang_icon" src="../assets/images/rus.png" alt="Русский" />
		</button>
		<button class="lang_button" @click="changeLang('en')">
			<img class="lang_icon" src="../assets/images/usa.png" alt="English" />
		</button>
	</div>
</template>

<style>
.lang_container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
}

.lang_button {
	width: 20vw;
}

.lang_icon {
	width: 70%;
	height: 70%;
	padding: 20%;
}
</style>
