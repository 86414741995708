<script setup>
import HomeScreen from '../components/screens/HomeScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("background.png");
});
</script>

<template>
    <HomeScreen/>
</template>