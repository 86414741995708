<script setup>
import router from "@/router.js";
import { discription_info_p1, discription_info_p2, discription_info_p3, discription_info_p4_1, discription_info_p4_2, discription_info_p4_3, discription_info_p5, info, loadLoc, roadmap } from "@/utils/localize";
import { onMounted } from "vue";

const openRoadmap = async () => {
    await router.push({
        name: "roadmap"
    })
};

onMounted(async () => {
    await loadLoc();
});
</script>

<template>
    <main class="scroll-info-background">
        <h1 class="header-info">{{ info }}</h1>
        <div class="text-info scrollable-element">
            <p class="center">
                {{ discription_info_p1 }}
            </p>
            <p>
                {{ discription_info_p2 }}
            </p>
            <p>
                {{ discription_info_p3 }}
            </p>
            <ol class="list">
                <li data-number="1">{{ discription_info_p4_1 }}</li>
                <li data-number="2">{{ discription_info_p4_2 }}</li>
                <li data-number="3">{{ discription_info_p4_3 }}</li>
            </ol>
            <p>
                {{ discription_info_p5 }}
            </p>
        </div>
        <button class="roadmap-button" @click="openRoadmap">
            <p>{{ roadmap }}</p>
        </button>
    </main>
</template>

<style>
    .scroll-info-background {
        width: 80vw;
        max-height: 60vh;
        display: flex;
        background-image: url(../../assets/images/scroll-info.png);
        background-size: cover;
        justify-content: center;
        flex-direction: column;
        background-repeat: round;
        flex-wrap: nowrap;
        align-items: center;
        padding-bottom: 10vw;
    }

    .header-info {
        color: #753f16;
        font-size: 6vw;
        margin: 3%;
    }

    .text-info {
        color: #753f16;
        font-size: 4vw;
        width: 75%;
        overflow: overlay;
    }

    .center {
        text-align: center;
    }

    .roadmap-button {
        display: flex;
        justify-content: center;
        color: #753f16;
        background-image: url(../../assets/images/button_bg.png);
        background-size: cover;
        background-repeat: round;
        font-size: 3vw;
        font-family: PIXY;
        padding-left: 5%;
        padding-right: 5%;
    }

    .list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        overflow: auto;
    }
</style>