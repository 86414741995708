<script setup>
import BarrelScreen from '../components/screens/BarrelScreen.vue';
import { onMounted } from 'vue';
import { changeBackgroundUrl } from '@/utils/view';

onMounted(() => {
    changeBackgroundUrl("barrel_bg.png");
});
</script>

<template>
    <BarrelScreen/>
</template>