<script setup>
import { ref, onMounted } from 'vue';

const progress = ref(0);

const increaseProgress = () => {
    if (progress.value < 100) {
        progress.value += 10;
    }
};

onMounted(() => {
    const interval = setInterval(increaseProgress, 300);
    return () => clearInterval(interval);
});
</script>

<template>
    <main class="load_background">
        <img class="load_bee" src="../../../public/bees/default.gif" alt="bee" />
        <div class="honey">
            <span>Honey</span>
            <span>Harvest</span>
        </div>
        <div class="loadbar">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
        </div>
    </main>
</template>

<style>
.load_background {
    background-image: url('../../assets/images/woodback.jpg');
    position: absolute;
    background-repeat: round;
    background-size: cover;
    width: -webkit-fill-available;
    height: 100vh;
    font-family: 'PIXY';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
    z-index: 10000;
}

.loadbar {
    width: 100%;
    height: 25px;
    background-color: #611207;
    border-radius: 20px;
    border: 2px #000 solid;
    color: #fff;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #edac39;
    border-radius: 20px;
    transition: width 0.5s;
}

.honey {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F7B409;
    font-size: 10vw;
    gap: 10px;
}

.load_bee {
    width: 30vw;
    height: auto;   
    transform: translateY(0) rotate(-20deg);
    animation: loadBee 4s ease-in-out forwards
}

@keyframes loadBee {
    0% {
        transform: translateY(0) rotate(20deg);
    }
    25% {
        transform: translateY(5vh) rotate(-20deg);
    }
    75% {
        transform: translateY(-5vh) rotate(-20deg);
    }
    100% {
        transform: translateY(0) rotate(20deg);
    }
}
</style>  